<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="question-basic">
          <el-form label-width="auto" size="mini">
            <el-form-item label="问题">
              <el-input
                v-model="submitData.QuestionTitle"
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select
                v-model="submitData.QuestionType"
                :disabled="submitData.QuestionId != 0"
                @change="onChangeQuestionType"
              >
                <el-option
                  v-for="item in questionTypeDefine"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- <template v-if="submitData.QuestionId"> -->
            <el-form-item
              v-if="submitData.QuestionType == 'singlecho'"
              label="答案"
            >
              <div class="answers-box">
                <draggable
                  class="list-group"
                  v-model="submitData.AnswerItems"
                  group="radiobtn"
                  handle=".answers-item"
                  animation="300"
                >
                  <div
                    class="answers-item radio"
                    v-for="(answerItem, answerIndex) in submitData.AnswerItems"
                    :key="answerIndex"
                  >
                    <i
                      class="el-icon-remove delete"
                      @click="onDeleteAnswer(answerIndex)"
                    ></i>
                    <el-input
                      v-model="answerItem.AnswerModel.Label"
                      clearable
                    ></el-input>
                  </div>
                </draggable>
                <div class="extra">
                  <div class="btn-box">
                    <el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-plus"
                      plain
                      @click="onAddAnswer('radiobtn')"
                      >添加答案</el-button
                    >
                  </div>
                  <!-- <div class="setting-box"></div> -->
                </div>
              </div>
            </el-form-item>

            <el-form-item
              v-else-if="submitData.QuestionType == 'multicho'"
              label="答案"
            >
              <div class="answers-box">
                <draggable
                  class="list-group"
                  v-model="submitData.AnswerItems"
                  group="checkbox"
                  handle=".answers-item"
                  animation="300"
                >
                  <div
                    class="answers-item checkbox"
                    v-for="(answerItem, answerIndex) in submitData.AnswerItems"
                    :key="'checkbox' + answerIndex"
                  >
                    <i
                      class="el-icon-remove delete"
                      @click="onDeleteAnswer(answerIndex)"
                    ></i>
                    <el-input
                      v-model="answerItem.AnswerModel.Label"
                      clearable
                    ></el-input>
                    <!-- <el-button
                      v-show="answerItem.AnswerId == 0"
                      type="success"
                      icon="el-icon-check"
                    ></el-button> -->
                  </div>
                </draggable>

                <div class="extra">
                  <div class="btn-box">
                    <el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-plus"
                      plain
                      @click="onAddAnswer('checkbox')"
                      >添加答案</el-button
                    >
                  </div>
                  <!-- <div class="setting-box"></div> -->
                </div>
              </div>
            </el-form-item>

            <el-form-item
              v-else-if="submitData.QuestionType == 'textinput'"
              label="最大字数"
            >
              <div class="answers-box">
                <div class="answers-item textarea">
                  <el-input
                    v-model.number="
                      submitData.AnswerItems[0].AnswerModel.MaxChars
                    "
                    clearable
                  ></el-input>
                </div>
              </div>
            </el-form-item>

            <template v-else-if="submitData.QuestionType == 'scorecho'">
              <el-form-item label="初始分数">
                <div class="answers-box">
                  <div class="answers-item rate">
                    <el-input
                      v-model.number="
                        submitData.AnswerItems[0].AnswerModel.InitScore
                      "
                      @change="
                        submitData.AnswerItems[0].AnswerModel.InitScore =
                          $event < 0 || $event == '' ? 0 : Number($event)
                      "
                    ></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="分数递增">
                <div class="answers-box">
                  <div class="answers-item rate">
                    <el-input
                      v-model.number="
                        submitData.AnswerItems[0].AnswerModel.IncScore
                      "
                      @change="
                        submitData.AnswerItems[0].AnswerModel.IncScore =
                          $event < 1 ? 1 : Number($event)
                      "
                    ></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="最大分数设置">
                <div class="answers-box">
                  <div class="answers-item rate">
                    <el-input
                      v-model.number="
                        submitData.AnswerItems[0].AnswerModel.MaxScore
                      "
                      @change="
                        submitData.AnswerItems[0].AnswerModel.MaxScore =
                          $event < 1 ? 1 : Number($event)
                      "
                    ></el-input>
                  </div>
                </div>
              </el-form-item>
            </template>
            <!-- </template> -->
            <el-form-item label="顺序">
              <el-input style="width: 100px;" v-model.number="submitData.Sort" clearable></el-input>
            </el-form-item>
          </el-form>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveQuestionEditor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

import draggable from "vuedraggable";

export default {
  components: { draggable },

  data() {
    return {
      title: "",
      typeName: "",
      questionTypeDefine: [],
      answerModelDefine: [
        {
          Label: "",
          Type: "radiobtn",
        },
        {
          Label: "",
          Type: "checkbox",
        },
        {
          MaxChars: 100,
          Type: "inputbox",
        },
        {
          IncScore: 1,
          InitScore: 1,
          MaxScore: 100,
          Type: "scorebar",
        },
      ],
      submitData: {
        ThemeId: 0,
        QuestionId: 0,
        QuestionTitle: "",
        QuestionType: "",
        Sort: 1,
        AnswerItems: [],
      },
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化问卷问题和答案数据
    async intQuestionEditor() {
      try {
        let { data } = await Survey.intQuestionEditor({
          theme_id: this.submitData.ThemeId,
          question_id: this.submitData.QuestionId,
        });
        if (data.editor && data.editor.AnswerItems.length > 0) {
          await data.editor.AnswerItems.forEach(
            (e) => (e.AnswerModel = JSON.parse(e.AnswerModel))
          );
        }
        this.submitData = (await data.editor) || (await this.submitData);
        this.questionTypeDefine = data.questionTypeDefine;
        this.answerModelDefine = data.answerModelDefine;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存问卷问题和答案数据
    async saveQuestionEditor() {
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      if (!submitData.QuestionTitle) {
        return this.$message.error("请输入问题文本");
      } else if (!submitData.QuestionType) {
        return this.$message.error("请选择问题类型");
      }

      await submitData.AnswerItems.forEach((e, i) => {
        e.Sort = i + 1;
        e.AnswerModel = JSON.stringify(e.AnswerModel);
      });
      // console.log(submitData);
      try {
        let { errcode } = await Survey.saveQuestionEditor(submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    onSubmit() {
      // console.log(this.submitData);
      this.$emit("success", this.submitData);
      this.onCloseDialog();
    },

    // 问题类型转换答案类型
    questionAndAnswerTypeChange() {
      switch (this.submitData.QuestionType) {
        case "multicho":
          return "checkbox";
        case "textinput":
          return "inputbox";
        case "scorecho":
          return "scorebar";
        default:
          return "radiobtn";
      }
    },

    // 问题类型切换事件
    onChangeQuestionType() {
      this.submitData.AnswerItems = [];
      let type = this.questionAndAnswerTypeChange(this.submitData.QuestionType);
      this.onAddAnswer(type);
    },

    // 添加问题
    onAddAnswer(type) {
      let index = this.answerModelDefine.findIndex((e) => e.Type == type);
      this.submitData.AnswerItems.push({
        AnswerType: type,
        AnswerModel: JSON.parse(JSON.stringify(this.answerModelDefine[index])),
        AnswerId: 0,
      });
      console.log(this.submitData.AnswerItems);
    },

    // 删除问题
    onDeleteAnswer(index) {
      this.submitData.AnswerItems.splice(index, 1);
    },

    // 重置数据
    resetData() {
      this.submitData = {
        ThemeId: 0,
        QuestionId: 0,
        QuestionTitle: "",
        QuestionType: "",
        AnswerItems: [],
      };
    },

    // 打开对话框
    onShowDialog(event = {}) {
      let param = JSON.parse(JSON.stringify(event));
      this.title = event.QuestionId ? "编辑问题" : "新增问题";
      this.submitData = { ...this.submitData, ...param };
      // console.log(this.submitData);
      this.intQuestionEditor();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .answers-box {
    .flex-col;

    .list-group {
      width: 100%;
    }

    .answers-item {
      .flex-row;
      width: 100%;
      padding-block-end: 10px;

      .delete {
        color: @error-color;
      }

      .el-input {
        width: 200px;
        box-sizing: border-box;
      }
    }

    .radio,
    .checkbox {
      .el-input {
        width: 200px;
        margin-inline-start: 10px;
        box-sizing: border-box;
      }
    }

    .extra {
      .flex-col;
      &,
      > div {
        width: 100%;
      }
      .btn-box {
        .flex-row;
      }
    }

    i {
      font-size: 20px;
    }

    .rate {
      .el-input {
        width: 100px;
      }
    }
  }
}
</style>
