<template>
  <div class="list">
    <div class="df-row">
      <div class="df-col">
        <el-input v-model="submitData.word" placeholder="请输入关键字" size="mini" clearable></el-input>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>
      <div class="df-col">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="onShowAdd"
          >添加问卷</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="ThemeName" label="名称"></el-table-column>
      <el-table-column prop="ThemeDesc" label="描述"></el-table-column>
      <el-table-column prop="" label="创建时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.CreateTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="更新时间">
        <template slot-scope="scope">
          {{
            scope.row.UpdateTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.UpdateTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="问卷内容" width="100px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="onShowContent(scope.row)"
            >设置</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onShowAdd(scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            :disabled="deleteDisabled"
            @click="deleteTheme(scope.row)"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            :disabled="enabledDisabled"
            @click="enableStoreUsage(scope.row)"
            >使用</el-button
          >
          <!-- <el-button type="info" size="mini" plain @click="">复制</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[15, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <!-- 添加问卷基本信息对话框 -->
    <AddQuestionnaireDialog
      ref="AddQuestionnaireDialog"
      @success="getThemeMaintList"
    ></AddQuestionnaireDialog>

    <!-- 问卷调研内容对话框 -->
    <QuestionnaireContentDialog
      ref="QuestionnaireContentDialog"
      @success=""
    ></QuestionnaireContentDialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

import AddQuestionnaireDialog from "./components/add-questionnaire-dialog.vue";
import QuestionnaireContentDialog from "./components/questionnaire-content-dialog.vue";

export default {
  components: { AddQuestionnaireDialog, QuestionnaireContentDialog },

  data() {
    return {
      tableMaxHeight: 0,
      totalRecord: 0,
      tableData: [],
      submitData: {
        word: "",
        page_index: 1,
        page_size: 20,
      },
      loading: false,
      deleteDisabled: false,
      enabledDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.getThemeMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取问卷列表
    async getThemeMaintList() {
      this.loading = true;
      try {
        let { data } = await Survey.getThemeMaintList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除问卷
    deleteTheme(event) {
      this.$confirm("确定删除该问卷吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.deleteDisabled = true;
        try {
          let { errcode } = await Survey.deleteTheme({
            theme_id: event.ThemeId,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getThemeMaintList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.deleteDisabled = false;
        }
      });
    },

    // 使用问卷
    enableStoreUsage(event) {
      this.$confirm("确定使用该问卷吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.enabledDisabled = true;
        try {
          let { errcode } = await Survey.enableStoreUsage({
            theme_id: event.ThemeId,
          });
          if (errcode == 0) {
            this.$message.success("使用成功");
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.enabledDisabled = false;
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getThemeMaintList();
    },

    // 打开添加/修改问卷基本信息对话框
    onShowAdd(event) {
      this.$refs.AddQuestionnaireDialog.onShowDialog(event);
    },

    // 打开问卷调研内容对话框
    onShowContent(event) {
      this.$refs.QuestionnaireContentDialog.onShowDialog(event);
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getThemeMaintList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getThemeMaintList();
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  .df-row {
    margin-top: 20px;
  }

  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
