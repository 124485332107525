<template>
  <div>
    <el-dialog
      title="问卷调研内容设置"
      :visible.sync="showDialog"
      width="600px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span v-loading="loading" class="content-box">
        <div class="questionnaire-box">
          <div
            class="questionnaire-item"
            v-for="(questionItem, questionIndex) in renderInfo.QuestionList"
            :key="questionIndex"
          >
            <div class="main">
              <div class="title">
                <span class="required">*</span>
                <span>{{
                  `${questionIndex + 1}.${
                    questionItem.QuestionTitle
                  }【${changeQuestionType(questionItem.QuestionType)}】`
                }}</span>
              </div>

              <div class="answer-box">
                <template v-if="questionItem.QuestionType === 'singlecho'">
                  <el-row :gutter="20">
                    <div
                      class="answer-item"
                      v-for="(answerItem,
                      answerIndex) in questionItem.AnswerList"
                      :key="answerIndex"
                    >
                      <el-col :span="11">
                        <el-radio :value="0">{{
                          answerItem.AnswerModel.Label
                        }}</el-radio>
                      </el-col>
                    </div>
                  </el-row>
                </template>
                <template v-if="questionItem.QuestionType === 'multicho'">
                  <el-row :gutter="20">
                    <div
                      class="answer-item"
                      v-for="(answerItem,
                      answerIndex) in questionItem.AnswerList"
                      :key="answerIndex"
                    >
                      <el-col :span="11">
                        <el-checkbox :value="0">{{
                          answerItem.AnswerModel.Label
                        }}</el-checkbox>
                      </el-col>
                    </div>
                  </el-row>
                </template>
                <template v-if="questionItem.QuestionType === 'scorecho'">
                  <div class="answer-item">
                    <div class="rate">
                      <div
                        v-for="(item, index) in questionItem.ScoreList"
                        :key="index"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="questionItem.QuestionType === 'textinput'">
                  <div
                    class="answer-item"
                    v-for="(answerItem, answerIndex) in questionItem.AnswerList"
                    :key="answerIndex"
                  >
                    <el-input type="textarea" :rows="2" readonly> </el-input>
                  </div>
                </template>
              </div>
            </div>

            <div class="operate-box">
              <div
                class="operate-item"
                @click="onShowAddQuestion(questionItem)"
              >
                <i class="el-icon-edit-outline"></i>
                <span>编辑</span>
              </div>
              <div class="operate-item" @click="onDeleteQuestion(questionItem)">
                <i class="el-icon-delete"></i>
                <span>删除</span>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-box">
          <el-button
            type="primary"
            plain
            size="mini"
            icon="el-icon-plus"
            @click="onShowAddQuestion({})"
            >添加问题</el-button
          >
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">关 闭</el-button>
        <!-- <el-button type="primary" @click="">确 定</el-button> -->
      </span>
    </el-dialog>

    <AddQuestionItemDialog
      ref="AddQuestionItemDialog"
      @success="getRenderData"
    ></AddQuestionItemDialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

import AddQuestionItemDialog from "./add-question-item-dialog.vue";

export default {
  components: { AddQuestionItemDialog },
  data() {
    return {
      themeId: 0,
      renderInfo: {
        QuestionList: [], // 问卷详情
        ThemeId: 0,
        ThemeName: "",
      },
      showDialog: false,
      loading: false,
    };
  },

  computed: {
    changeQuestionType() {
      return function(type) {
        switch (type) {
          case "singlecho":
            return "单选";
          case "multicho":
            return "多选";
          case "scorecho":
            return "评分";
          default:
            return "文本";
        }
      };
    },
  },

  mounted() {},

  methods: {
    // 获取问卷内容详情
    async getRenderData() {
      this.loading = true;
      try {
        let { data } = await Survey.getRenderData({
          theme_id: this.themeId,
        });
        await data.QuestionList.forEach((item) => {
          item.AnswerList.forEach((answerItem) => {
            answerItem.AnswerModel = JSON.parse(answerItem.AnswerModel);
            // console.log(answerItem);
            if (answerItem.AnswerModel.Type === "scorebar") {
              item.ScoreList = [];
              for (
                let i = answerItem.AnswerModel.InitScore;
                i <= answerItem.AnswerModel.MaxScore;
                i += answerItem.AnswerModel.IncScore
              ) {
                item.ScoreList.push(i);
              }
            }
          });
        });
        console.log(data);
        this.renderInfo = await data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除问题
    onDeleteQuestion(event) {
      this.$confirm("确定删除该问题吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Survey.deleteQuestion({
            question_id: event.QuestionId,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getRenderData();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 打开添加/编辑问题对话框
    onShowAddQuestion(item = {}) {
      this.$refs.AddQuestionItemDialog.onShowDialog({
        ...item,
        ThemeId: this.renderInfo.ThemeId,
        Sort:
          item.Sort ||
          (this.renderInfo.QuestionList.length
            ? this.renderInfo.QuestionList[
                this.renderInfo.QuestionList.length - 1
              ].Sort + 1
            : 1),
      });
    },

    resetData() {
      this.renderInfo = {
        QuestionList: [],
        ThemeId: 0,
        ThemeName: "",
      };
    },

    // 打开对话框
    onShowDialog(event) {
      this.themeId = event.ThemeId;
      this.getRenderData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .questionnaire-box {
    .flex-col;
    align-items: unset;
    max-height: 70vh;
    overflow-y: auto;

    .questionnaire-item {
      .flex-row;
      align-items: unset;
      margin-block: 5px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 5px;

      .main{
        .flex-col;
        align-items: unset;
        flex: 1;
      }

      .title {
        margin: 10px;
        font-size: 16px;
        .required {
          color: red;
        }
      }

      .answer-box {
        margin: 0 10px 10px;
        .answer-item {
          font-size: 16px;

          .rate {
            .flex-row;
            padding: 0 10px;
            > div {
              .flex-row;
              justify-content: center;
              flex: 1;
              height: 30px;
              border: 1px solid #ccc;
            }
          }
        }
      }

      .operate-box {
        // display: none;
        .flex-col;
        align-items: unset;
        background-color: #f8f8f8;
        // border-top: 1px solid #e6e6e6;
        border-radius: 0 5px 5px 0;
        border-left: 1px solid #e6e6e6;

        .operate-item {
          .flex-col;
          justify-content: center;
          // width: calc(100% / 5);
          flex: 1;
          padding: 5px;
          color: #777;
          cursor: pointer;

          i {
            font-size: 16px;
          }

          > span {
            font-size: 12px;
          }
        }
      }

      // &:hover {
      //   .operate-box {
      //     .flex-row;
      //   }
      // }
    }
  }
  .btn-box {
    // padding: 0 20px;
    .el-button {
      width: 100%;
    }
  }
}

.scrollbar;
</style>
