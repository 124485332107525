<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto">
          <el-form-item label="名称">
            <el-input v-model="submitData.ThemeName"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input
              type="textarea"
              rows="6"
              v-model="submitData.ThemeDesc"
            ></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveTheme">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

export default {
  data() {
    return {
      title: "",
      submitData: {
        ThemeId: 0,
        ThemeName: "",
        ThemeDesc: "",
      },
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化问卷信息
    async initTheme() {
      try {
        let { data } = await Survey.initTheme({
          theme_id: this.submitData.ThemeId,
        });
        this.submitData = data.editor || this.submitData;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存问卷信息
    async saveTheme() {
      try {
        let { errcode } = await Survey.saveTheme(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 重置数据
    resetData() {
      this.submitData = {
        ThemeId: 0,
        ThemeName: "",
        ThemeDesc: "",
      };
    },

    // 打开对话框
    onShowDialog(event = "") {
      this.submitData.ThemeId = event.ThemeId || 0;
      this.title = this.submitData.ThemeId ? "修改问卷" : "添加问卷";
      this.initTheme();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>
